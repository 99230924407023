import styled from 'styled-components';

const CornBidStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .corn-bids-img-container {
        position: relative;
        text-align: center;
        color: white;
    }
    .corn-bids-img-container > img {
        width: 100%;
    }

    .corn-bids-img-container > div {
        position: absolute;
        top: 60%;
        left: 21%;
        transform: translate(-50%, -50%);
        color: rgba(255, 255, 255, 1);
        font-size: 50px;
        font-weight: 500;
        font-family: Roboto;
    }

    .cornBid-flexcontainer {
        margin: 0 auto;
        @media screen and (min-width: 1440px) {
            max-width: 1260px;
        }
    }

    .cornBid-flexsubContainer {
        display: flex;
        justify-content: space-between;
        margin: 80px;
    }

    .corn-bid-flex-heading {
        width: 45%;
    }

    .corn-bid-flex-heading h2 strong
    {
        font-family: Roboto;
        font-weight: 500 !important;
    }

    .corn-bid-flex-select {
        width: 40%;
        margin-right: 120px;
        display: flex;
        justify-content: right;

        @media (min-width: 320px) and (max-width: 767px) {
            justify-content: left;
        }
    }

    /* .location-arrows {
        width: 100%;
        background-color: rgba(240, 240, 240, 1);
        border: thin solid rgba(223, 223, 223, 1);
        display: inline-block;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-weight: 800;
        font-size: 18px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
        background-position: calc(100% - 0.5rem), 100% 0;
        background-size: 1.5em 1.5em;
        background-repeat: no-repeat;
        border-radius: 0 !important;
    }

    .location-arrows:focus {
        outline: 0 !important;
    } */

    .cornBid-subHeading {
        margin: 80px;
        font-family: Roboto;
    }
    .cornBid-subHeading-sm {
        font-size: 11px;
        line-height: 20px;
        color: rgba(170, 170, 170, 1);
    }

    .cashwidget-btn-primary {
        height: 46px;
        width: 244px;
        background-color: rgba(68, 136, 62, 1);
        color: rgba(255, 255, 255, 1);
        font-family: Lato;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 15px;
        text-align: center;
        margin-top: 15px;
        border: none;
    }

    .cornBid-subHeading-md {
        line-height: 22px;
        font-size: 16px;
        color: rgba(170, 170, 170, 1);
    }
    .cornBid-subHeading-md:nth-child(4) {
        margin: 20px 0;
    }
    .dark {
        color: rgba(45, 46, 44, 1);
        font-weight: 700;
    }

    .cornBid-para {
        margin: 20px auto;
    }

    .emergency-contact-content {
        height: 39px;
        text-align: left;
        color: ${(props) => props.theme.colors.secondary};
        font: 700 1.75rem ${(props) => props.theme.fonts[0]}, sans-serif;
        letter-spacing: 0;
        line-height: 2.5rem;
    }

    /*dtn widget styling*/
    #cash-bids-table-widget {
        display: block;
        margin: 10px;
    }
    #cash-bids-table-widget-panel {
        margin: 80px;
    }
    .exclamation-icon {
        width: 60px;
        height: 60px;
        display: block;
        margin: 10px;
    }

    select {
        border: 2px solid rgba(223, 223, 223, 1);
        border-radius: 6px;
        color: rgba(51, 51, 51, 1);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
    }

    dtn-radiogroup-label
    {
        font-size: 18px !important;
        font-family: Roboto !important;
    }

    dtn-radiogroup fieldset legend
    {
        font-family: Roboto !important;
    }

    dtn-select .label
    {
        left: 20px !important;
    }

    dtn-select label select
    {
        padding-left: 20px !important;
        height: 48px;
    }


    .cash-bids-table-widget-1br3ftk > * + * {
        margin-left: 50px;
    }

    legend {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
    }

    .cash-bids-table-widget-1xfyw7x .label {
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
    }
    
    .cash-bids-table-widget-1mmwt76 {
        display: none;
    }
    .cash-bids-table-widget-bypqqb {
        display: none;
    }
    table {
        margin: 30px auto !important;
    }
    tr:nth-child(even) {
        background-color: rgba(240, 240, 240, 1);
    }
    th {
        color: rgba(102, 102, 102, 1) !important;
        font-size: 14px !important;
        width: 200px !important;
    }
    /**/

    /*Media querries*/
    @media (min-width: 320px) and (max-width: 767px) {
        .corn-bids-img-container > img {
            height: 160px;
        }
        .corn-bids-img-container > div {
            font-size: 25px;
        }
        .cornBid-flexcontainer {
            margin: 0;
        }
        .cornBid-flexsubContainer {
            flex-direction: column;
            margin: 50px;
        }
        .corn-bid-flex-heading {
            width: 100%;
        }
        .corn-bid-flex-select {
            width: 90%;
            margin-top: 50px;
        }
        .cornBid-subHeading {
            margin: 50px;
        }

        /*dtn widget styling*/
        #cash-bids-table-widget-panel {
            margin: 50px;
        }
    }
    /**/

    dtn-radiogroup-radio input:checked ~ dtn-radiogroup-indicator 
    {
        // TODO SVG: old SVG (commented out) doesn't break the below "dtn-select" selector, but for some reason the new SVG data does even with no other changes.
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E763AEE55-2977-455C-93C4-20686AEDD153%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Web-2038---GA-Contact-Copy' transform='translate(-752.000000, -651.000000)'%3E%3Cg id='Checked-circle' transform='translate(752.000000, 651.000000)'%3E%3Ccircle id='Oval' stroke='%23979797' stroke-width='2' cx='11' cy='11' r='10'%3E%3C/circle%3E%3Ccircle id='Oval-Copy' fill='%23979797' cx='11' cy='11' r='5.2962963'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        //background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='8px' viewBox='0 0 16 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EF64E9AD2-9F78-4EA9-8DC8-6D130559F8A2%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Web-2038---GA-Contact-Copy' transform='translate(-656.000000, -660.000000)' fill='%23333300' fill-rule='nonzero'%3E%3Cpolygon id='Path' points='656 660 664 668 672 660'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        
        background-size: contain;
    }
`;

export default CornBidStyled;
