
const createCashBidsTableWidget = (container_id) => {
    window.dtn.cashBids.createCashBidsTableWidget({
        apiKey: process.env.CASH_BIDS_WIDGETS,
        container: container_id,
        siteId: process.env.SITE_ID,
        groupBy: 'LOCATION',
        showGroupByOptions: true,
        view: 'DETAIL',
        showViewOptions: true,
        showLocationsSelect: true,
        showCommoditiesSelect: true,
        defaultLocation: {
            locationName: 'Green America - Ord',
        },    
        deliveryDateFormat: 'DELIVERY_END',
        visibleFields: [
            'DELIVERY_END',
            'CASH_PRICE',
            'BASIS_PRICE',
            'SYMBOL',
            'FUTURES_QUOTE',
            'FUTURES_CHANGE',
        ],
    });
};

const DTNServices = {
    createCashBidsTableWidget
}

export default DTNServices;
