import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withSize } from 'react-sizeme';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CornBidStyled from '../components/pageStyles/cornBids-styles';
import useGlobal from '../store';
import ReactHtmlParser from 'react-html-parser';
import DTNService from '../services/DTNService';
import backgroundImage from '../images/green america/corn/cornbids.jpg';

const CornBidsPage = ({ path, size }) => {
    const [globalState, globalActions] = useGlobal();
    const defaultSection_arr = [{ main: [] }];
    const defaultSection_obj = {};
    const containerRef = useRef(null);
    const { current } = containerRef;
    const {
        cornbids: {
            corn_bids_config: [{ main: corn_bids_arr }] = defaultSection_arr,
        },
    } = globalState;

    const {
        cornbids: { cash_bids_widget: cash_bids_widget = defaultSection_obj },
    } = globalState;

    const LoadCashBids = () => {
        try {
            DTNService.createCashBidsTableWidget(
                '#cash-bids-table-widget-panel'
            );
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        LoadCashBids();
    }, [current]);

    const cornbidData =
        corn_bids_arr?.images &&
        corn_bids_arr?.images.length > 0 &&
        corn_bids_arr?.images[0];

    return (
        <Layout path={path}>
            <SEO title="CornBids" />
            <CornBidStyled>
                <div>
                    <div className="corn-bids-img-container">
                        <img
                            src={cornbidData?.file?.url}
                            alt={cornbidData?.custom_data}
                        />
                        <div>{cornbidData?.custom_data}</div>
                    </div>
                </div>
                <div className="cornBid-flexcontainer">
                    <div className="cornBid-flexsubContainer">
                        <div className="corn-bid-flex-heading">
                            <h2>
                                <strong>{cash_bids_widget?.header}</strong>
                            </h2>
                        </div>
                        <div className="corn-bid-flex-select">
                            {/* <select className="location-arrows" disabled>
                                <option value="All Locations">
                                    All Locations
                                </option>
                            </select> */}
                            <div>
                                <a
                                    target="_blank"
                                    href={cash_bids_widget?.button?.url?.href}
                                >
                                    <button className="cashwidget-btn-primary">
                                        {cash_bids_widget?.button?.label}
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        id="cash-bids-table-widget-panel"
                        className="cash-bids-table-widget"
                        ref={containerRef}
                    />

                    <div className="cornBid-subHeading">
                        {ReactHtmlParser(cash_bids_widget.footer)}
                    </div>
                </div>
            </CornBidStyled>
        </Layout>
    );
};

CornBidsPage.propTypes = {
    size: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default withSize()(CornBidsPage);
